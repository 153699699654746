export const REDUCER_KEY: '@writeaway' = '@writeaway';

export enum Actions {
  /* GLOBAL */
  GLOBAL_SHOW_MESSAGE = '@writeaway/GLOBAL_SHOW_MESSAGE',

  /* NAVBAR */
  NAVBAR_EXPAND = '@writeaway/NAVBAR_EXPAND',
  NAVBAR_COLLAPSE = '@writeaway/NAVBAR_COLLAPSE',

  /* INJECTIONS */
  ATTACH_COMPONENT = '@writeaway/ATTACH_COMPONENT',
  SET_API = '@writeaway/SET_API',
  SET_META = '@writeaway/SET_META',

  EXPERT_MODE = '@writeaway/EXPERT_MODE',

  /* PIECES */
  PIECES_ENABLE_EDIT = '@writeaway/PIECES_ENABLE_EDIT',
  PIECES_DISABLE_EDIT = '@writeaway/PIECES_DISABLE_EDIT',
  PIECES_SET_SOURCE_ID = '@writeaway/PIECES_SET_SOURCE_ID',
  PIECES_ACTIVATION_SENT_PIECE = '@writeaway/PIECES_ACTIVATION_SENT_PIECE',
  PIECES_ACTIVATE_PIECE = '@writeaway/PIECES_ACTIVATE_PIECE',
  PIECES_DEACTIVATION_SENT_PIECE = '@writeaway/PIECES_DEACTIVATION_SENT_PIECE',
  PIECES_DEACTIVATE_PIECE = '@writeaway/PIECES_DEACTIVATE_PIECE',
  PIECE_UPDATE = '@writeaway/PIECE_UPDATE',
  PIECE_RESET = '@writeaway/PIECE_RESET',
  PIECE_ADD = '@writeaway/PIECE_ADD',
  PIECE_REMOVE = '@writeaway/PIECE_REMOVE',
  PIECE_SET_DATA = '@writeaway/PIECE_SET_DATA',
  PIECE_HAS_REMOVED = '@writeaway/PIECE_HAS_REMOVED',
  PIECE_SET_MESSAGE = '@writeaway/PIECE_SET_MESSAGE',

  PIECE_FETCHING = '@writeaway/PIECE_FETCHING',
  PIECE_FETCHED = '@writeaway/PIECE_FETCHED',
  PIECE_FETCHING_FAILED = '@writeaway/PIECE_FETCHING_FAILED',
  PIECE_FETCHING_ERROR = '@writeaway/PIECE_FETCHING_ERROR',

  PIECE_SAVING = '@writeaway/PIECE_SAVING',
  PIECE_SAVED = '@writeaway/PIECE_SAVED',
  PIECE_SAVING_FAILED = '@writeaway/PIECE_SAVING_FAILED',

  PIECES_EDITOR_ACTIVE = '@writeaway/PIECES_EDITOR_ACTIVE',
  PIECES_HOVERED = '@writeaway/PIECES_HOVERED',

  /* I18N */
  I18N_INIT = '@writeaway/I18N_INIT',
  I18N_FIND = '@writeaway/I18N_FIND',
  I18N_ADD = '@writeaway/I18N_ADD',
  I18N_SET_ELEMENTS = '@writeaway/I18N_SET_ELEMENTS',
  I18N_TOGGLE_EDIT = '@writeaway/I18N_TOGGLE_EDIT',
  I18N_ENABLE_EDIT = '@writeaway/I18N_ENABLE_EDIT',
  I18N_DISABLE_EDIT = '@writeaway/I18N_DISABLE_EDIT',
  I18N_UPDATE_DATA = '@writeaway/I18N_UPDATE_DATA',
  I18N_SAVE = '@writeaway/I18N_SAVE',
  I18N_SAVED = '@writeaway/I18N_SAVED',

  /* PAGES */
  PAGES_SET_CURRENT_INDEX = '@writeaway/PAGES_SET_CURRENT_INDEX',
  PAGES_GET_STARTED = '@writeaway/PAGES_GET_STARTED',
  PAGES_GET_FINISHED = '@writeaway/PAGES_GET_FINISHED',
  PAGES_GET_ERROR = '@writeaway/PAGES_GET_ERROR',

  PAGE_START_CREATING = '@writeaway/PAGE_START_CREATING',
  PAGE_CANCEL_CREATING = '@writeaway/PAGE_CANCEL_CREATING',
  PAGE_UPDATE = '@writeaway/PAGE_UPDATE',
  PAGE_DATA_UPDATE = '@writeaway/PAGE_DATA_UPDATE',
  PAGE_DATA_FIELDS_UPDATE = '@writeaway/PAGE_DATA_FIELDS_UPDATE',
  PAGE_DATA_FIELDS_SET = '@writeaway/PAGE_DATA_FIELDS_SET',

  PAGE_ADD = '@writeaway/PAGE_ADD',

  PAGE_SAVING = '@writeaway/PAGE_SAVING',
  PAGE_SAVE_ERROR = '@writeaway/PAGE_SAVE_ERROR',
  PAGE_SAVED = '@writeaway/PAGE_SAVED',

  PAGE_DELETING = '@writeaway/PAGE_DELETING',
  PAGE_DELETE_ERROR = '@writeaway/PAGE_DELETE_ERROR',
  PAGE_DELETED = '@writeaway/PAGE_DELETED',

  PAGES_GET_LAYOUTS_STARTED = '@writeaway/PAGES_GET_LAYOUTS_STARTED',
  PAGES_GET_LAYOUTS_FINISHED = '@writeaway/PAGES_GET_LAYOUTS_FINISHED',
  PAGES_GET_LAYOUTS_ERROR = '@writeaway/PAGES_GET_LAYOUTS_ERROR'
}
