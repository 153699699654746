(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("autobind-decorator"), require("js-beautify"), require("@writeaway/core"), require("react-modal"), require("react-simple-code-editor"));
	else if(typeof define === 'function' && define.amd)
		define("@writeaway/codemirror", ["react", "autobind-decorator", "js-beautify", "@writeaway/core", "react-modal", "react-simple-code-editor"], factory);
	else if(typeof exports === 'object')
		exports["@writeaway/codemirror"] = factory(require("react"), require("autobind-decorator"), require("js-beautify"), require("@writeaway/core"), require("react-modal"), require("react-simple-code-editor"));
	else
		root["WriteAwayPluginCodemirror"] = factory(root["react"], root["autobind-decorator"], root["js-beautify"], root["@writeaway/core"], root["react-modal"], root["react-simple-code-editor"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 