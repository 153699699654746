(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("medium-editor"), require("autobind-decorator"), require("@writeaway/core"), require("react-dom"), require("classnames"));
	else if(typeof define === 'function' && define.amd)
		define("@writeaway/medium", ["react", "medium-editor", "autobind-decorator", "@writeaway/core", "react-dom", "classnames"], factory);
	else if(typeof exports === 'object')
		exports["@writeaway/medium"] = factory(require("react"), require("medium-editor"), require("autobind-decorator"), require("@writeaway/core"), require("react-dom"), require("classnames"));
	else
		root["WriteAwayPluginMedium"] = factory(root["react"], root["medium-editor"], root["autobind-decorator"], root["@writeaway/core"], root["react-dom"], root["classnames"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 