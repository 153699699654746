(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("autobind-decorator"), require("js-beautify"), require("react-dom"), require("react-modal"), require("react-simple-code-editor"), require("@writeaway/core"));
	else if(typeof define === 'function' && define.amd)
		define("@writeaway/seo", ["react", "autobind-decorator", "js-beautify", "react-dom", "react-modal", "react-simple-code-editor", "@writeaway/core"], factory);
	else if(typeof exports === 'object')
		exports["@writeaway/seo"] = factory(require("react"), require("autobind-decorator"), require("js-beautify"), require("react-dom"), require("react-modal"), require("react-simple-code-editor"), require("@writeaway/core"));
	else
		root["WriteAwayPluginSeo"] = factory(root["react"], root["autobind-decorator"], root["js-beautify"], root["react-dom"], root["react-modal"], root["react-simple-code-editor"], root["@writeaway/core"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__) {
return 