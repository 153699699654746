(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-redux"), require("react-redux-toastr"), require("classnames"), require("redux"), require("react-dom"), require("autobind-decorator"), require("redux-devtools-extension"), require("redux-thunk"));
	else if(typeof define === 'function' && define.amd)
		define("@writeaway/core", ["react", "react-redux", "react-redux-toastr", "classnames", "redux", "react-dom", "autobind-decorator", "redux-devtools-extension", "redux-thunk"], factory);
	else if(typeof exports === 'object')
		exports["@writeaway/core"] = factory(require("react"), require("react-redux"), require("react-redux-toastr"), require("classnames"), require("redux"), require("react-dom"), require("autobind-decorator"), require("redux-devtools-extension"), require("redux-thunk"));
	else
		root["WriteAway"] = factory(root["react"], root["react-redux"], root["react-redux-toastr"], root["classnames"], root["redux"], root["react-dom"], root["autobind-decorator"], root["redux-devtools-extension"], root["redux-thunk"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__) {
return 