export const pickerColors = [
  'inherit',
  '#9b59b6',
  '#34495e',
  '#16a085',
  '#27ae60',
  '#2980b9',
  '#8e44ad',
  '#2c3e50',
  '#f1c40f',
  '#e67e22',
  '#e74c3c',
  '#bdc3c7',
  '#95a5a6',
  '#666',
  '#212121',
  '#f39c12',
  '#d2d064',
  '#4fbbf7',
  '#ffffff',
];
